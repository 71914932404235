<template>
  <div class="container-warp">
    <div class="top-box">
      <div>
        <div class="return-title">
          <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
          <span class="division">|</span>
          <div class="back-title">课程详情</div>
        </div>
        <div class="name-box">
          <com-title>{{courseIntroduceInfo.courseName}}</com-title>
          <div>
          </div>
          <div class="name-box-r">
            <span>课程编号 {{courseIntroduceInfo.courseCode||'—'}}</span>
            <span class="line-box"></span>
            <span>课程时长 共{{courseIntroduceInfo.sectionNum}}节 <span v-if="currentLeftContent?.fileType==='video'">| {{courseIntroduceInfo.fileDuration?formatSeconds(courseIntroduceInfo.fileDuration):'—'}}</span></span>
          </div>
        </div>
      </div>
      <!--课程目录学习---开始 -->
      <div class="video-box">
        <!-- 左侧展示 start -->
        <div class="video-box-l mytable-scrollbar" ref="scrollPdf">
          <!-- 视频格式--开始 -->
          <div v-if="currentLeftContent?.fileType==='video'">
            <vedioPlay
              style="height:100%;width:100%;"
              ref="vedioPlay"
              :key="refreshVedio"
              :currentLeftContent="currentLeftContent"
              :src="currentLeftContent?.sourceFileUrl"
              @updateVideoPace="_updateVideoPace"
              @updateVideoPaceOld="_updateVideoPaceOld">
            </vedioPlay>
          </div>
          <!-- 视频格式--结束 -->
          <!-- 文件格式---开始 -->
          <div v-else-if="currentLeftContent?.fileType==='file'">
            <iframe
              id="inlineFrameExample"
              allow="fullscreen"
              width="98%"
              height="600"
              :src="currentUrl">
            </iframe>
          </div>
          <!-- 文件格式---结束 -->
          <!-- 图片格式---开始 -->
          <div v-else-if="currentLeftContent?.fileType==='image'">
            <img style="max-width: 783px; max-height: 484px;" :src="currentLeftContent?.sourceFileUrl"/>
          </div>
          <!-- 图片格式---结束 -->
          <div v-else style="max-width: 783px; max-height: 484px;">
            <div class="empty-box">
              <PlaceholderMap text="暂无数据~" />
            </div>
          </div>
          <!-- 左侧展示 end -->
        </div>
        <div class="video-box-r mytable-scrollbar">
          <div class="catalogue-box">
            <img src="@/assets/img/icon_catalogue.png" alt="" />
            <span>目录</span>
          </div>
          <div v-for="(item,index) in courseDirectoryData" :key="item.sectionId" :class="currentActiveCourse==item.sectionId ?'course-active':''">
            <div class="catalogue-box-item" @click="_selectCourseDirBtn(item,index)">
              <div class="catalogue-box-item-l">
                <i v-if="item?.fileType==='video'" class="iconfont icon-icon_course"></i>
                <i v-else class="iconfont icon-icon_course_name"></i>
                <div class="courrse-name">
                  {{item.sectionName}}
                </div>
              </div>
              <div class="catalogue-box-item-r" v-if="item?.fileType==='video'">{{(formatSeconds(item.sectionDuration))}}</div>
              <div class="catalogue-box-item-r" v-else>{{item?.fileSuffix}}</div>
            </div>
          </div>
        </div>
      </div>
      <!--课程目录学习---结束 -->
    </div>
    <div class="bottom-box" style="margin-top: 16px">
    <el-tabs v-model="activeName" :style="{'--font-Color' : fontColor}">
        <el-tab-pane label="课程介绍" name="0">
          <div style="height: 400px;overflow-y: auto;">
            <div style="margin-bottom:24px;">
              <com-title>课程描述</com-title>
              <div class="describe-text">
                {{courseIntroduceInfo.description||'—'}}
              </div>
            </div>
            <div>
            <com-title>课程介绍内容</com-title>
            <div class="describe-text">
              <div v-if="courseIntroduceInfo.introduce">
                <span v-html="courseIntroduceInfo.introduce"></span>
              </div>
              <div v-else>
                —
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="课程资料" name="1">
        <div style="height: 400px;overflow-y: auto;">
          <div class="empty-box" v-if="courseMaterialData.length === 0">
            <PlaceholderMap text="暂无课程资料噢~" />
          </div>
          <div class="item-content" v-for="(item,index) in courseMaterialData" :key="index">
            <div class="item-left">
              <img src="@/assets/img/text.png" alt="" />
              <div class="hover-name">{{item.fileName}}</div>
            </div>
            <div class="item-right">
              <el-button type="text" @click="downloadFileDetail(item)" v-if="courseAllow == 1">下载</el-button>
              <el-button type="text" @click="_previewFileHandle(item,index)">查看</el-button>
              <!-- <el-button type="text" @click="_deleteTrainee(item,index)">删除</el-button> -->
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    </div>
    <!-- 列表预览--开始 -->
    <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      :currentFile="currentFile"
      ref="previewFile"
      ></PreviewFile>
  </div>
</template>
<script>
import comTitle from '@/components/comTitle/index.vue'
import vedioPlay from '@/components/vedioPlay/index.vue'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
import PreviewFile from '@/components/previewFile/index.vue'
import fileType from '@/mixins/fileType'
import { getStudyDetailApi, uploadStudyProgressApi } from '@/api/study'
export default {
  mixins: [fileType],
  components: {
    comTitle,
    vedioPlay,
    PlaceholderMap,
    PreviewFile
  },
  data () {
    return {
      fontColor: this.$store.state.settings.theme,
      activeName: '0',
      previewFileShow: false, // 图片&视频预览
      courseIntroduceInfo: {}, // 课程介绍
      courseDirectoryData: [], // 课程目录
      courseMaterialData: [], // 课程资料
      currentActiveCourse: 0, // 当前激活的目录
      refreshVedio: new Date().getTime(),
      currentLeftContent: {}, // 当前左侧内容信息
      autoplay: false,
      queryId: null,
      leaveCurrentPage: true,
      courseAllow: null,
      currentCourseId: ''
    }
  },
  computed: {

  },
  watch: {
    'currentLeftContent' (newContentValue, oldContentValue) {
      this.newVideoInfo = newContentValue
      this.oldVideoInfo = oldContentValue
    }

  },
  created () {
    this.queryId = this.$route.query.learningCourseId
    if (this.$route.query.learningCourseId) {
      this._getOnlineCourseDetail(this.$route.query.learningCourseId, 'init')
    }
    this.fontColor = this.$store.state.settings.theme
  },
  // vue路由的钩子函数 , 可以放在mouted的同级
  beforeRouteLeave (to, from, next) {
    if (this.leaveCurrentPage) { // 未离开当前页面
      next()
    } else { // 如果是当前浏览的是视频 离开当前页面-暂停视频，并保存视频进度，其他不需处理
      if (this.$refs.vedioPlay && this.$refs.vedioPlay.isPlay) {
        this.$refs.vedioPlay.play()// 暂停视频
        this.uploadStudyProgress(this.$refs.vedioPlay.video.currentTime)
      }
      next()
    }
  },
  mounted () {
    document.addEventListener('visibilitychange', this.handleVisiable, false)
  },
  // 页面销毁之前
  beforeDestory () {
    document.removeEventListener('visibilitychange', this.handleVisiable, false)
  },
  // 页面销毁
  destroyed () {
    document.removeEventListener('visibilitychange', this.handleVisiable, false)
  },
  methods: {
    // 获取详情
    _getOnlineCourseDetail (learningCourseId, type, item) {
      getStudyDetailApi({ learningCourseId: learningCourseId }).then((res) => {
        if (res.code === 200) {
          const { courseMaterials, courseIntroduction, leaningDirectories, courseAllow } = res.data
          this.courseAllow = courseAllow
          if (this.courseAllow == 1) {
            this.downloadFile(item)
          }
          this.currentCourseId = res.data.courseId
          this.courseIntroduceInfo = JSON.parse(JSON.stringify(courseIntroduction))
          this.courseMaterialData = courseMaterials
          this.courseDirectoryData = leaningDirectories
          if (type === 'init') {
            // 初始化 如果该课程未学习过，就需要默认为第一条，否则默认为之前已打卡的目录
            const tempCurrentLeftContent = this.courseDirectoryData.filter((item) => item.learningMarker === 1)[0]
            if (tempCurrentLeftContent) {
              this.currentLeftContent = tempCurrentLeftContent
            } else {
              this.currentLeftContent = this.courseDirectoryData[0]
            }
            this.currentActiveCourse = this.currentLeftContent?.sectionId
            this.currentUrl = this.getPreviewUrl(this.currentLeftContent)// 处理文件预览格式
            this.courseMaterialData = res.data.courseMaterials
          }
        }
      })
    },
    // 选择右侧课程目录
    _selectCourseDirBtn (item, index) {
      this._getOnlineCourseDetail(this.$route.query.learningCourseId)
      this.refreshVedio = new Date().getTime()
      if (item.fileType === 'file') {
        this.currentUrl = this.getPreviewUrl(item)// 处理文件预览格式
      }
      this.currentActiveCourse = item.sectionId
      this.currentLeftContent = item
      setTimeout(() => {
        this._updateDirMarker(item)// 更新右侧目录
      }, 500)
    },
    // 更新视频进度
    uploadStudyProgress (currentTime, currentFileInfo) {
      // console.log(currentTime, this.currentLeftContent.mediaProgress, this.currentLeftContent.sectionId)
      // console.log(this.currentLeftContent.mediaProgress, currentTime)
      const params = {
        learningCourseId: this.$route.query.learningCourseId, // 课程id（正在学习ID）
        sectionId: currentFileInfo ? currentFileInfo.sectionId : this.currentLeftContent.sectionId, // 小节id
        sectionType: currentFileInfo ? 'video' : this.currentLeftContent.fileType, // 文件类型
        courseId: this.$route.query.courseId || this.currentCourseId, // 课程id
        deltaDuration: (Number(currentTime) - Number(this.currentLeftContent.mediaProgress || currentFileInfo.mediaProgress)) || 0, // 当前视频增量时间
        mediaProgress: currentTime // 当前视频播放进度
      }
      uploadStudyProgressApi(params).then(res => {
        if (res.code === 200) {
          this._getOnlineCourseDetail(this.$route.query.learningCourseId)
        }
      })
      // uploadStudyProgressApi({
      //   ...item,
      //   sectionType: item.fileType,
      //   deltaDuration: item.fileType === 'video' ? (parseInt(item.learnDuration) - parseInt(item.mediaProgress || 0)) : 0,
      //   mediaProgress: item.fileType === 'video' ? item.learnDuration || 0 : 0,
      //   learningCourseId: Number(this.queryId)
      // }).then(res => {
      //   if (res.code === 200) {

      //   }
      // })
    },

    // 更新目录标记
    _updateDirMarker (item) {
      console.log(item, 'item更新目录')
      uploadStudyProgressApi({
        learningCourseId: this.$route.query.learningCourseId, // 课程id（正在学习ID）
        sectionId: item.sectionId, // 小节id
        sectionType: item.fileType, // 文件类型
        courseId: this.$route.query.courseId || this.currentCourseId, // 课程id
        mediaProgress: item.mediaProgress, // 当前视频进度
        deltaDuration: 0 // 当前增量时间（只是更新目录）
      }).then(res => {
        if (res.code === 200) {}
      })
    },

    // 查看课程资料
    _previewFileHandle (row, index) {
      // 列表预览
      if (row.fileType === 'image' || row.fileType === 'video') {
        this.previewFileShow = true
        this.currentFile = row
      } else {
        const previewUrl = this.getPreviewUrl(row)
        window.open(previewUrl)
      }
    },

    // 更新视频进度
    _updateVideoPace (currentTime) {
      // console.log('222222222222222')
      this.uploadStudyProgress(this.$refs.vedioPlay.video.currentTime, this.currentLeftContent)
    },
    _updateVideoPaceOld (currentTimeOld) {
      console.log(this.$route.name, '视频实例销毁 了 ')
      if (this.$route.name === 'onlineCourseDetail') {
        // 当old为视频时,--暂停视频，并更新视频进度，如果old不是视频不需要处理
        if (this.oldVideoInfo.fileType === 'video') {
        // 当old为视频时，如果正在播放，需要暂停并更新视频进度
          // console.log('old1-----------')
          this.uploadStudyProgress(currentTimeOld, this.oldVideoInfo)
        }
      }
    },
    async downloadFileDetail (item) {
      await this._getOnlineCourseDetail(this.$route.query.learningCourseId, '', item)
    },
    // 监听课程观看进度---页面切换保存视频进度
    handleVisiable (e) {
      e = e || window.event
      e.returnValue = '离开此页面？'
      switch (e.target.visibilityState) {
        case 'prerender':
          break
        case 'hidden':
          if (this.$refs.vedioPlay.isPlay) {
            this.$refs.vedioPlay.play()// 暂停视频
            // console.log('内容不可见，处理后台、最小化、锁屏状态')
            console.log('333333333333')
            this.uploadStudyProgress(this.$refs.vedioPlay.video.currentTime, this.currentLeftContent)
          }
          break
        case 'visible':
          // console.log('处于正常打开')
          break
      }
    },
    _backBtn () {
      if (this.$route.query.pageValue === 'studying') { // 正在学习
        this.$router.push({
          path: '/myStudying'
        })
      }
      if (this.$route.query.pageValue === 'onlineCourse') { // 在线课程
        this.$router.push({
          path: '/courseDetail',
          query: {
            id: this.$route.query.id
          }
        })
      }
      if (this.$route.query.pageValue === 'trainRecord') { // 培训记录
        this.$router.push({
          path: '/trainingRecord'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/iconfont/iconfont.css";
/deep/.el-tabs__item.is-active{
    color:var(--font-Color)!important;
  }
  /deep/.el-tabs__item{
    color: #737373!important;
  }
.top-box,
.bottom-box {
  padding: 16px 24px;
  background-color: #ffffff;
  border-radius: 4px;
}
// 头部返回标题样式
.return-title{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  .back-btn{
    font-size: 14px;
    color: #303133;
  }
  .division{
    color: #F3F3F3;
    width: 1px;
    background-color: #F3F3F3;
    border-radius: 2px;
    margin: 0px 16px;
  }
  .back-title{
    font-size: 16px;
    color: #333333;
  }
}
/deep/.title-box {
  margin-bottom: 16px;
}
.name-box {
  display: flex;
  align-items: center;
  .name-box-r {
    display: flex;
    align-items: center;
    margin-left: 12px;
    font-size: 12px;
    color: #666666;
    .line-box {
      display: inline-block;
      margin: 0 12px;
      width: 1px;
      height: 16px;
      background-color: #dcdcdc;
    }
  }
}
.video-box {
  // width: 60%;
  // height: 60vh;
  // max-height: 60vh!important;
  height: 70vh!important;
  display: flex;
  margin-top: 16px;
  .video-box-l{
    padding: 24px;
    // background: linear-gradient(to bottom, #F5FBFF, #ffffff);
    background: #F5FBFF;
    // border: 1px solid;
    // border-image: linear-gradient(to bottom, #E7F4FF, #D1EBFF) 1;
    width: 70%;
    // max-height: 60vh!important;
    overflow-y: scroll;
    position: relative!important;
  }
  .video-box-r {
    width: 30%;
    min-height: 60vh!important;
    max-height: 70vh!important;
    background:#FAFDFF;
    overflow-y: scroll;
    .catalogue-box {
      position: sticky;
      top: 0px;
      left: 0px;
      display: flex;
      align-items: center;
      padding: 12px 24px;
      background: linear-gradient(180deg, #f5fbff 0%, #ffffff 100%);
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
      }
    }
    .course-active{
      background-color:#EBF6FF ;
    }
    .catalogue-box-item {
      padding: 7px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4d4d4d;
      .catalogue-box-item-l {
        display: flex;
        align-items: center;
        font-size: 14px;
        .icon-icon_course,
        .icon-icon_course_name {
          margin-right: 14px;
          font-size: 20px;
          color: #0089ff;
        }
      }
      .catalogue-box-item-r {
        font-size: 12px;
      }
    }
  }
}
.bottom-box {
  .describe-text {
    margin-top: 8px;
    font-size: 14px;
    color: #4d4d4d;
  }
}
.content {
  background: url("../../assets/img/data-bg.png");
  padding: 12px 24px;
  height:  calc(100vh - 440px);
  overflow: auto;
  .item-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0;

    &:last-of-type {
      border: none;
    }
    .item-left {
      display: flex;
      align-items: center;
      img {
        width: 38px;
        height: 38px;
        margin-right: 16px;
      }
    }
  }
}
/*滚动条整体部分*/
.mytable-scrollbar ::-webkit-scrollbar {
  width: 1px;
  height: 10px;
}
/*滚动条的轨道*/
.mytable-scrollbar ::-webkit-scrollbar-track {
  background-color: #ffffff;
}
/*滚动条里面的小方块，能向上向下移动*/
.mytable-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}
.mytable-scrollbar ::-webkit-scrollbar-thumb:active {
  background-color: #787878;
}
/*边角，即两个滚动条的交汇处*/
.mytable-scrollbar ::-webkit-scrollbar-corner {
  background-color: #ffffff;
}
</style>
